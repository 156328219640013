import { User } from "src/domains/myPageContent/accountInfo/user-query/user.model";
import { convertToNumberWithoutNationCode } from "./validationUtils";

export const transformUser = (userInfo: User) => {
  if (!userInfo) return null;

  const customAttrs = userInfo["custom:customAttrs"]
    ? JSON.parse(userInfo["custom:customAttrs"])
    : {};

  return {
    name: userInfo.name,
    email: userInfo.email,
    phone_number: convertToNumberWithoutNationCode(userInfo.phone_number),
    job: customAttrs.job || null,
    company: customAttrs.company || null,
    field: customAttrs.field || null,
  };
};
