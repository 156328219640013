import { css } from "@emotion/react";
import { BsFillTelephoneFill } from "react-icons/bs";
import Image from "next/image";
import { COLOR } from "../shared/constants/style";

const ProjectFooter = () => {
  return (
    <div css={FooterContainer}>
      <div css={flex}>
        <div css={imgDiv}>
          <Image src="/images/soosung.png" width={260} height={73} alt="logo" />
        </div>
        <div css={infoDiv}>
          <div css={terms}>
            <a css={aTag}>회사 정보</a>
            <a css={aTag}>이용약관</a>
            <a css={aTag}>개인정보 처리방침</a>
            <a css={aTag}>제휴/이용 문의</a>
          </div>
          <div css={addressRight}>
            <p css={companyText}>
              본사&nbsp;&nbsp;&emsp;&ensp;| 경상남도 창원시 성산구 창이대로 510,
              403호(용호동,경남보훈회관)
            </p>
            <p css={companyText}>
              서울지사&nbsp;| 서울특별시 송파구 정의로8길 13 (수성위너스)
            </p>
          </div>
          <p css={copyrightText}>
            COPYRIGHT © SOOSUNG ENGINEERING CO.,LTD. ALL RIGHTS RESERVED.
          </p>
        </div>
        <div css={phoneDiv}>
          <div css={phoneIcon}>
            <BsFillTelephoneFill />
          </div>
          <p css={phoneText}>대표전화 00-0000-0000</p>
        </div>
      </div>
    </div>
  );
};

const FooterContainer = css`
  position: relative;
  display: flex;
  width: 100%;
  background-color: ${COLOR.Gray100};
  color: ${COLOR.Gray700};
  padding: 30px 160px 30px 150px;
  justify-content: space-between;
`;

const flex = css`
  display: flex;
  width: 100%;
`;
const infoDiv = css`
  align-items: center;
  margin-left: 70px;
  width: 60%;
`;

const phoneDiv = css`
  display: flex;
  width: 40%;
  align-items: flex-start;
`;

const copyrightText = css`
  font-size: 12px;
`;

const terms = css`
  display: flex;
  justify-content: space-between;
  width: 400px;
`;
const addressRight = css`
  padding: 20px 0 15px;
`;
const companyText = css`
  margin: 0;
`;
const aTag = css`
  text-decoration: none;
  color: ${COLOR.Gray700};
  font-weight: 700;
  :hover {
    text-decoration: none;
    color: ${COLOR.Gray700};
    cursor: pointer;
  }
`;

const phoneIcon = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 4px solid ${COLOR.Gray700};
  color: ${COLOR.Gray700};
  border-radius: 50%;
`;

const phoneText = css`
  font-weight: 700;
  color: ${COLOR.Gray700};
  font-size: 20px;
  margin-left: 10px;
`;

const imgDiv = css`
  width: 15%;
`;

export default ProjectFooter;
