/* eslint-disable import/no-anonymous-default-export */
import { API, Auth } from "aws-amplify";
import { ParsedUser, User } from "./user.model";

class UserRepository {
  async getUser(): Promise<User> {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    return user.attributes;
  }

  async deleteUser() {
    return API.del("DronesquareApi", "users/me", {});
  }

  async patchUser(data: ParsedUser) {
    return API.patch("DronesquareApi", "users/me", {
      body: {
        ...data,
        phone_number_verified: "true",
        email_verified: "true",
      },
    });
  }
}

export default new UserRepository();
