import { atom } from "recoil";
import { DEFAULT_MAP_LOCATION } from "src/domains/shared/constants/constants";
import { v1 } from "uuid";

interface Project {
  id: number | undefined;
  location: location | null;
}

type location = { lng: number; lat: number };

export const project = atom<Project>({
  key: `codeKey/${v1()}`,
  default: { id: undefined, location: null },
});

export const searchProject = atom<{
  address: string;
  location: location | null;
}>({
  key: `codeKey/${v1()}`,
  default: { address: "서울", location: null },
});

export const projectHeader = atom({
  key: `codeKey/${v1()}`,
  default: { title: "프로젝트명", address: "지역", infra_type: "객체 타입" },
});
