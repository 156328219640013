import { localeString } from "../constants/locale";

export const validationPassword = (password: string, locale: "en" | "ko") => {
  const warnList: string[] = [];
  const regaxForValSignUp = {
    moreThanEightLength: {
      warnText: localeString.myPage.msg.passwordLength[locale],
      fn: new RegExp("(?=.{8,})"),
    },
    atLeastOneUpperCaseLetter: {
      warnText: localeString.myPage.msg.passwordNeedCapital[locale],
      fn: new RegExp("(?=.*[A-Z])"),
    },
    atLeastOneLowerCaseLetter: {
      warnText: localeString.myPage.msg.atLeastOneLowerCaseLetter[locale],
      fn: new RegExp("(?=.*[a-z])"),
    },
    atLeastOneDigit: {
      warnText: localeString.myPage.msg.atLeastOneDigit[locale],
      fn: new RegExp("(?=.*[0-9])"),
    },
    atLeastOneSpecialCharacter: {
      warnText: localeString.myPage.msg.atLeastOneSpecialCharacter[locale],
      fn: new RegExp("([^A-Za-z0-9])"),
    },
  };
  for (const [key, value] of Object.entries(regaxForValSignUp)) {
    if (!value.fn.test(password)) {
      warnList.push(value.warnText);
    }
  }
  return warnList;
};

export const validationPhoneNumber = (
  phoneNumber: string,
  locale: "en" | "ko"
) => {
  const warnList: string[] = [];

  // 자리수 검증 (10, 11자리)
  if (phoneNumber.length < 10 || phoneNumber.length > 11) {
    warnList.push(localeString.myPage.msg.notEnoughLength[locale]);
  }
  if (!Number(phoneNumber)) {
    // 숫자만 들어오는지
    warnList.push(localeString.myPage.msg.onlyNumber[locale]);
  }

  return warnList;
};

export const convertToNumberWithNationCode = (phoneNumber: string) => {
  const removedFirstNumber = phoneNumber.slice(1, phoneNumber.length).concat();
  return `+82${removedFirstNumber}`;
};

export const convertToNumberWithoutNationCode = (phoneNumber: string) => {
  const removedNationCode = phoneNumber.slice(3, phoneNumber.length);
  return `0${removedNationCode}`;
};

export const validateNumber = (value: string) => {
  const numRegExp = /^[0-9]*$/;
  return numRegExp.test(value);
};

export const validateFirstDigit = (value: string) => {
  const firstDigitRegExp = /^(?!0$)\d{1,2}(\.\d)?$/;

  return firstDigitRegExp.test(value);
};
