import { Auth } from "aws-amplify";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SIGNIN_ERROR } from "../constants/constants";
import { queryClient } from "src/pages/_app";
import { useDeleteUser } from "src/domains/myPageContent/accountInfo/user-query/user.queries";
import notice from "../utils/notice";
import useLocale from "./localeHook/useLocale";
import { localeString } from "../constants/locale";

type AuthCondition = "LOGIN" | "LOGOUT";

export interface SignupForm {
  username: string;
  email: string;
  phone: string;
  password1: string;
  password2?: string;
  company: string;
  job: string;
  field: string;
}

export const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

export const useAuth = () => {
  const [locale, setLocale] = useLocale();
  const { mutate: deleteAccount } = useDeleteUser();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [emailBeforeConfirm, setEmailBeforeConfirm] = useState<string>("");
  const [authCondition, setAuthCondition] = useState<AuthCondition>("LOGOUT");
  const [error, setError] = useState<any>({});
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCurrentUser = async () => {
    try {
      const user = await getUser();
      if (!user) {
        setIsLoading(true);
      }
      if (user) {
        setIsLoading(false);
        setCurrentUser(user);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const result = await Auth.signIn(email, password);
      setAuthCondition("LOGIN");
      router.push("/");
      return result;
    } catch (error) {
      const message = SIGNIN_ERROR[error.name];
      if (message) {
        setError({ message: message.message[locale.locale], name: error.name });
        return;
      }
      setError({
        message: locale.locale === "en" ? "Server Error" : "서버 에러",
      });
    }
  };

  const signUp = async (userSignupForm: SignupForm) => {
    try {
      await Auth.signUp({
        username: userSignupForm.email,
        password: userSignupForm.password1,
        attributes: {
          name: userSignupForm.username,
          email: userSignupForm.email,
          phone_number: userSignupForm.phone,
          "custom:customAttrs": JSON.stringify({
            job: userSignupForm.job,
            field: userSignupForm.field,
            company: userSignupForm.company,
          }),
        },
      });
      setEmailBeforeConfirm(userSignupForm.email);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const confirmSignUpCode = async (data: { code: string }) => {
    try {
      await Auth.confirmSignUp(emailBeforeConfirm, data.code);
      router.push("/login");
      notice("success", localeString.myPage.msg.joinCompleted[locale.locale]);
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setAuthCondition("LOGOUT");
      setCurrentUser(null);
      router.replace("/");
      queryClient.clear();
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const signOff = () => {
    deleteAccount(null, {
      onSuccess: () => {
        notice(
          "success",
          localeString.myPage.msg.accountDeleted[locale.locale]
        );
        signOut();
      },
      onError: (err) => {
        setError(err);
        notice(
          "error",
          localeString.myPage.msg.failedDeletingAcount[locale.locale]
        );
      },
    });
  };

  useEffect(() => {
    getCurrentUser();
  }, [authCondition]);

  return {
    currentUser,
    confirmSignUpCode,
    signIn,
    signUp,
    signOut,
    signOff,
    error,
    isLoading,
  };
};
