import { useEffect } from "react";
import useLocale from "./hooks/localeHook/useLocale";
import { css } from "@emotion/react";
import { AiOutlineGlobal } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { projectHeader } from "src/store/projectsStore";

export default function Locale({ isShown = true }: { isShown?: boolean }) {
  const [locale, setLocale] = useLocale();
  const [projectHeaderState, setProjectHeader] = useRecoilState(projectHeader);

  const toggleLocale = () => {
    if (locale.locale === "ko") {
      setLocale({ locale: "en" });
      if (projectHeaderState.title === "프로젝트명") {
        setProjectHeader({
          title: "Project Title",
          address: "Address",
          infra_type: "Infra type",
        });
      }
    } else {
      setLocale({ locale: "ko" });
      if (projectHeaderState.title === "Project Title") {
        setProjectHeader({
          title: "프로젝트명",
          address: "지역",
          infra_type: "객체 타입",
        });
      }
    }
  };

  useEffect(() => {
    setProjectHeader({
      title: "Project Title",
      address: "Address",
      infra_type: "Infra type",
    });
  }, []);

  if (!isShown) return <></>;

  return (
    <div
      css={css`
        z-index: 1000;
        position: fixed;
        justify-content: flex-start;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          margin-top: 5px;
        `}
      >
        <AiOutlineGlobal />
      </div>
      <div
        css={css`
          margin-left: 5px;
        `}
      >
        <button
          onClick={toggleLocale}
          css={css`
            background-color: transparent;
            outline: none;
            border: none;
            font-weight: ${locale.locale === "ko" ? 800 : 300};
            color: ${locale.locale === "ko" ? "white" : "#797979"};
          `}
        >
          ko
        </button>
      </div>
      <div
        css={css`
          margin-left: 5px;
        `}
      >
        <button
          onClick={toggleLocale}
          css={css`
            background-color: transparent;
            outline: none;
            border: none;
            font-weight: ${locale.locale === "en" ? 800 : 300};
            color: ${locale.locale === "en" ? "white" : "#797979"};
          `}
        >
          en
        </button>
      </div>
    </div>
  );
}
