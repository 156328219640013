import { toast, ToastOptions } from "react-toastify";

const notice = (
  status: "success" | "error",
  text: string,
  options?: ToastOptions
) => {
  const toastOptions = options ?? {};

  if (status === "success") {
    return toast.success(text, toastOptions);
  } else if (status === "error") {
    return toast.error(text, toastOptions);
  }
};

export default notice;
