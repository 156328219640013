import { css } from "@emotion/react";
import { memo } from "react";
import Navbar from "rsuite/Navbar";
import Nav from "rsuite/Nav";
import { useRouter } from "next/router";
import Image from "next/image";
import { useAuth } from "../../hooks/user";
import { COLOR } from "../../constants/style";
import { PUBLIC_STORAGE } from "../../constants/constants";
import SmallLoadingIndicator from "../SmallLoadingIndicator";
import useLocale from "../../hooks/localeHook/useLocale";
import { localeString } from "../../constants/locale";
import Locale from "../../Locale";

const HeaderRsuit = () => {
  const { currentUser, signOut } = useAuth();
  const [locale, setLocale] = useLocale();

  const router = useRouter();

  return (
    <Navbar css={headerContainer}>
      <Locale />
      <Nav>
        <Nav.Item>
          <Image
            priority={true}
            src="/logo_dronesquare_white.png"
            width={170}
            height={33}
            alt="logo"
            onClick={() => router.push("/")}
          />
        </Nav.Item>
      </Nav>
      <Nav pullRight>
        <Nav>
          <Nav.Menu title={localeString.header.service[locale.locale]}>
            <Nav.Item onClick={() => router.push("/accurate")}>
              {localeString.header.platform[locale.locale]}
            </Nav.Item>
            <Nav.Item onClick={() => router.push("/survey")}>
              {localeString.header.survey[locale.locale]}
            </Nav.Item>
          </Nav.Menu>
        </Nav>
        <Nav>
          <Nav.Item onClick={() => router.push("/plan")}>
            {localeString.header.plan[locale.locale]}
          </Nav.Item>
        </Nav>
        <Nav>
          <Nav.Item onClick={() => router.push("/inquiry")}>
            {localeString.header.contact[locale.locale]}
          </Nav.Item>
        </Nav>
        <Nav>
          <Nav.Item onClick={() => router.push("/manual")}>
            {localeString.header.manual[locale.locale]}
          </Nav.Item>
        </Nav>
        <Nav>
          {currentUser ? (
            <div css={flexStyle}>
              <Nav.Item onClick={() => router.push("/projects")}>
                {localeString.header.project[locale.locale]}
              </Nav.Item>
              <Nav.Item onClick={() => router.push("/mypage/info")}>
                {localeString.header.myPage[locale.locale]}
              </Nav.Item>
              <Nav.Item onClick={signOut}>
                {localeString.header.auth.logout[locale.locale]}
              </Nav.Item>
            </div>
          ) : (
            <div css={flexStyle}>
              <Nav.Item onClick={() => router.push("/login")}>
                {localeString.header.auth.login[locale.locale]}
              </Nav.Item>
            </div>
          )}
        </Nav>
      </Nav>
    </Navbar>
  );
};

const headerContainer = css`
  position: fixed;
  height: 57px;
  width: 100%;
  z-index: 1000;
  padding: 0 8% 0 150px;
  border-bottom: 1px solid ${COLOR.Gray550};
`;

const flexStyle = css`
  display: flex;
`;

export default memo(HeaderRsuit);
