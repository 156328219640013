import { useQuery, useMutation, UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";
import UserRepository from "./user.repository";
import { User, ParsedUser } from "./user.model";
import { transformUser } from "src/domains/shared/utils/transformedUserInfo";

export const useGetUser = (): Omit<UseQueryResult<ParsedUser>, "data"> & {
  data: ParsedUser | undefined;
} => {
  const queryInfo = useQuery<User>({
    queryKey: ["getUser"],
    queryFn: () => UserRepository.getUser(),
  });
  return {
    ...queryInfo,
    data: useMemo(() => transformUser(queryInfo.data), [queryInfo.data]),
  };
};

export const usePatchUser = () => {
  return useMutation({
    mutationKey: ["patchUser"],
    mutationFn: (data: ParsedUser) => UserRepository.patchUser(data),
  });
};

export const useDeleteUser = () => {
  return useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: () => UserRepository.deleteUser(),
  });
};
